import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import DirectCareSeoImage from '../assets/images/direct-patient-care.jpg'

class DirectCare extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query DirectCareMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout page='care'>
                    <Seo title={`Direct Primary Care | ${data.site.siteMetadata.title}`} description={`Direct Primary Care, also known as "concierge medicine," is an excellent alternative to the typical primary care relationship.`} image={DirectCareSeoImage} />
                    <Banner title={`Direct Primary Care`} titleColor='#FFFFFF' subTitleColor='#FFFFFF' />
                    <section id='content'>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <h4>The average time it takes for a patient to get an appointment with their PCP is two weeks or more. Patients trying to establish care with a physician wait even longer. The average length of a doctor's visit is 17 minutes, less than 6 of those minutes is spent with the patient talking. Busy people who care about their health and wellness shouldn't have to sacrifice their time and daily commitments just to be taken care of by their doctor.</h4>

                                    <p>Direct Primary Care, also known as "concierge medicine," is an excellent alternative to the typical primary care relationship. For a monthly fee, you become a member at Optimal Health and Wellness. As a member, you can have an appointment, via telehealth or house call to your home or business with Dr. Sambataro, as soon as the next day. In addition, you get access to the doctor via phone, email, or text anytime you need it. Most doctor's offices don't grant you the ability to discuss medical issues after office hours, direct care allows you the ability to get medical advice when you need it.</p>

                                    <p>Dr. Sambataro's approach to primary care allows for patient visits that can last up to an hour. The direct care approach allows for discussion regarding topics rarely covered in your typical office visit, such as, mood, sleep, sexual health, nutrition, energy, and daily life stressors. These topics are vital to your quality and quantity of life.</p>

                                    <p>Our membership based practice does not take insurance. Your health insurance will cover things like labs, imaging, medications and if necessary, hospitalization. Our practice prefers not to have insurance companies dictating how and when we take care of our patients.</p>

                                    <p>If you find that you are a busy person, who prefers to have a closer relationship with their physician, who will get to know you as a patient and not just as a chart. Direct primary care with Dr. Sambataro just may be for you!</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasAbout={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default DirectCare